import React from 'react';
import {ReactComponent as LogoIcon} from "../assets/logo.svg";

const Logo = () => {
  return (
    <div className="logo unbounded">
      <a href="#">
        <LogoIcon/>
        <div className="logo__text">Корпорація<br/>маркетингу</div>
      </a>
    </div>
  );
};

export default Logo;