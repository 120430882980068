import React, {useRef, useState} from 'react';
import {ReactComponent as Logo} from "../assets/logo-big.svg";
import AchievementElem from "../elements/AchievementElem";
import {setElemScrollPosition} from "../services/setElemScrollPosition";
import {stateContext} from "../store/store";

const WhoWeAre = () => {
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const ref1 = useRef<HTMLDivElement>(null);
  const ref2 = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.target === ref1.current) {
            setIsVisible1(entry.isIntersecting);
          } else if (entry.target === ref2.current) {
            setIsVisible2(entry.isIntersecting);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (ref1.current) observer.observe(ref1.current);
    if (ref2.current) observer.observe(ref2.current);

    return () => {
      if (ref1.current) observer.unobserve(ref1.current);
      if (ref2.current) observer.unobserve(ref2.current);
    };
  }, []);

  const {setKeyPositions} = React.useContext(stateContext)
  const elemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setElemScrollPosition(elemRef, setKeyPositions)
  }, []);

  return (
    <section id={"who-we-are"} className='who-we-are'>
      <img src={require("../assets/planete.png")} alt=""/>

      <div ref={ref1} className={`who-we-are__wrapper who-we-are__wrapper__for-logo ${isVisible1 ? 'visible' : ""}`}>
        <div className={'who-we-are__row'}>
          <div className={'who-we-are__logo'}>
            <Logo/>
          </div>
        </div>
        <div className={'who-we-are__row'}>
          <div ref={elemRef} className='slogan montserrat'>Ми віримо, що онлайн-простір відкриває <span>безмежні можливості</span>
          </div>
        </div>
      </div>

      <div className={"who-we-are__wrapper who-we-are__wrapper__for-achievements"}>
        <div className="achievement-area">
          <div className={'achievement-area__space'}/>
          <AchievementElem achievement={"20+ експертів з підтримки"}/>
          <AchievementElem img_path={require("../assets/achievement-img.png")}/>
          <AchievementElem/>
          <AchievementElem/>
          <AchievementElem achievement={"2000+ задоволених клієнтів"}/>
          <div className={'achievement-area__space achievement-area__space__mobile'}/>
          <AchievementElem achievement={"2 роки на ринку"}/>
        </div>
      </div>

      <p ref={ref2} className={`montserrat ${isVisible2 ? "visible__p" : ""}`}>Саме тому, з 2021 року <span
        className={"unbounded"}>Корпорація Маркетингу</span> забезпечує якісний комплексний супровід клієнтів на всіх
        етапах
        використання онлайн продуктів компанії, шляхом надання консультації від реєстрації до повного задоволення
        клієнта.</p>
    </section>
  );
};

export default WhoWeAre;