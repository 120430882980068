import React from 'react';

interface Props {
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void

  placeholder: string,
  id: string
  errorString: string
}

const InputField: React.FC<Props> = ({onChange, placeholder, id, errorString}) => {
  return (
    <div className={'input-field'}>
      <input className={`input-field__field ${errorString.length > 0 ? "input-field__field__error" : ""}`}
             onChange={onChange}
             type="text"
             placeholder={placeholder}
             id={id}
             name={id}/>
      <div className={'input-field__error'}>{errorString}</div>
    </div>
  );
};

export default InputField;