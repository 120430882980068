import React from 'react';
import Header from "./components/common/Header";
import Footer from "./components/common/Footer";
import Content from "./components/common/Content";
import {stateContext} from "./store/store";


function App() {
  const [keyPositions, setKeyPositions] = React.useState<number[]>([])

  return (
    <stateContext.Provider value={{keyPositions, setKeyPositions}}>
      <Header/>
      <Content/>
      <Footer/>
    </stateContext.Provider>
  );
}

export default App;
