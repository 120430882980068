import React, {FormEvent} from 'react';
import InputFile from "../elements/InputFile";
import {useForm} from "@formspree/react";
import {CandidateType} from "../types/CandidateType";
import InputField from "../elements/InputField";
import vacancy from "../elements/Vacancy";

const candidateDefault: CandidateType = {
  lastName: "",
  firstName: "",
  email: "",
  phone: "",
  cvFile: null
}

interface Props{
  onStatusChange(success: boolean, errors: any): void
}

const errorsDefault: {[key: string]: string} = {
  lastName: "",
  firstName: "",
  email: "",
  phone: "",
  cvFile: ""
}

const CvForm: React.FC<Props> = ({onStatusChange}) => {
  const [state, handleSubmit] = useForm("xayrwzzg");
  const [candidate, setCandidate] = React.useState(candidateDefault)
  const [errors, setErrors] = React.useState(errorsDefault)
  const nameRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?0-9]+/g;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^[\+]{0,1}380([0-9]{9})$/

  const submitForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errorStrings: {[key: string]: string} = {
      firstName: candidate.firstName.length <= 0
        ? "Будь ласка, вкажіть своє ім'я"
        : candidate.firstName.match(nameRegex)
          ? "Неправильний формат імʼя"
          : "",
      lastName: candidate.lastName.length <= 0
        ? "Будь ласка, вкажіть своє прізвище" : candidate.lastName.match(nameRegex)
          ? "Неправильний формат прізвища"
          : "",
      email: candidate.email.length <= 0
        ? "Будь ласка, вкажіть свій email"
        : emailRegex.test(candidate.email)
          ? ""
          : "Неправильний формат e-mail",
      phone: candidate.phone.length <= 0
        ? "Будь ласка, вкажіть свій телефон"
        : phoneRegex.test(candidate.phone)
          ? ""
          : "Неправильний формат телефону",
      cvFile: !candidate.cvFile ? "Будь ласка, завантажте своє резюме" : ""
    }

    setErrors(errorStrings)
    const errorsCheck = Object.keys(errorStrings).findIndex(item => errorStrings[item].length > 0)

    if (errorsCheck < 0)
      await handleSubmit(e);
  };

  const handleFNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidate({...candidate, firstName: e.target.value})
  }
  const handleLNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidate({...candidate, lastName: e.target.value})
  }
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidate({...candidate, email: e.target.value})
  }
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCandidate({...candidate, phone: e.target.value})
  }
  const handleFileChange = (item: File | null) => {
    setCandidate({...candidate, cvFile: item})
  }

  React.useEffect(() => {
    onStatusChange(state.succeeded, state.errors)
  }, [state.errors, state.succeeded])
  return (
    <form className={"form montserrat"} onSubmit={submitForm}>
      <InputField errorString={errors.firstName} onChange={handleFNameChange} placeholder={"Імʼя"} id={'firstName'}/>
      <InputField errorString={errors.lastName} onChange={handleLNameChange} placeholder={"Прізвище"} id={'lastName'}/>
      <InputField errorString={errors.email} onChange={handleEmailChange} placeholder={"Email"} id={'email'}/>
      <InputField errorString={errors.phone} onChange={handlePhoneChange} placeholder={"Телефон"} id={'phone'}/>
      <div className="form__row">
        <InputFile setFile={handleFileChange}/>
        <input disabled={state.submitting} type="submit" placeholder={state.submitting ? 'Відправка' : 'Надіслати'}/>
      </div>
      <div className={"form__file-info"}>
        {candidate.cvFile
          ? <span className={"file-name"}>Файл: {candidate.cvFile.name}</span>
          : <span>
            <div className={"form__file-error"} style={{color: "#FF81AE"}}>{errors.cvFile}</div>Файл .doc, .docx, .txt, .pdf
          </span>
        }

      </div>
    </form>
  );
};

export default CvForm;