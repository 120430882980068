/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 scene.gltf -t
*/

import * as THREE from 'three'
import React, {useRef, useState} from 'react'
import {useGLTF, PerspectiveCamera, useAnimations} from '@react-three/drei'
import {GLTF} from 'three-stdlib'
import {AnimationAction} from "three";
import {stateContext} from "../store/store";
import useViewPort from "../hooks/useViewPort";

type GLTFResult = GLTF & {
  nodes: {
    Cube006: THREE.Mesh
    Cube014: THREE.Mesh
    Cube015: THREE.Mesh
    Cube016: THREE.Mesh
    Cube013: THREE.Mesh
    Sphere008: THREE.Mesh
    Sphere009: THREE.Mesh
  }
  materials: {
    ['Material.004']: THREE.MeshStandardMaterial
    ['Material.005']: THREE.MeshStandardMaterial
  }
  animations: GLTFAction[]
}

type ActionName =
  'Animation'
  | 'ANIN_CUBE_6_MOB.001'
  | 'ANIN_CUBE_6_MAIN.001'
  | 'ANIN_CUBE_13_MOB.001'
  | 'ANIN_CUBE_13_MAIN.001'
  | 'ANIN_SPHARE_8_MOB.001'
  | 'ANIN_SPHARE_8_MAIN.001'
  | 'ANIN_SPHARE_9_MOB.001'
  | 'ANIN_SPHARE_9_MAIN.001'

interface GLTFAction extends THREE.AnimationClip {
  name: ActionName
}

type ContextType = Record<string, React.ForwardRefExoticComponent<JSX.IntrinsicElements['mesh']>>

function getFrame(scrollPoints: number[], scrollY: number, pageHeight: number, screenHeight: number, elemHeight: number) {
  const framePoints = [0, 5, 8, 11, 13.741109530583214, 17, 20.83333396911621];
  scrollPoints[scrollPoints.length - 1] -= (screenHeight - elemHeight) + 100
  scrollPoints[scrollPoints.length - 2] -= (screenHeight - elemHeight)

  for (let i = 0; i < scrollPoints.length - 1; i++) {
    if (scrollY >= scrollPoints[i] && scrollY < scrollPoints[i + 1]) {
      const progress = (scrollY - scrollPoints[i]) / (scrollPoints[i + 1] - scrollPoints[i]);
      return framePoints[i] + progress * (framePoints[i + 1] - framePoints[i]);
    }
  }

  return framePoints[framePoints.length - 1];
}

export function Model({scroll, elemH, ...props}: { scroll: number, elemH: number } & JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>(null)
  const {nodes, materials, animations} = useGLTF('/models/scene.gltf') as GLTFResult
  const {actions} = useAnimations(animations, group) as { actions: Record<string, AnimationAction> }
  const [mobileActions, setMobileActions] = useState<AnimationAction[]>([])
  const [desktopActions, setDesktopActions] = useState<AnimationAction[]>([])
  const [currentFrame, setCurrentFrame] = React.useState(0);
  const {keyPositions} = React.useContext(stateContext)
  const {pageHeight, vh, vw} = useViewPort()

  React.useEffect(() => {
    const mobile = []
    const desktop = []

    for (const name in actions) {
      if (name.includes('MOB')) {
        mobile.push(actions[name])
      } else if (name.includes('MAIN')) {
        desktop.push(actions[name])
      }
    }

    setMobileActions(mobile)
    setDesktopActions(desktop)
  }, [actions])

  React.useEffect(() => {
    const targetAnimation = vw > 685 ? desktopActions : mobileActions

    if (targetAnimation) {
      targetAnimation.forEach(item => {
        item.time = currentFrame;
        item.paused = true;
      })
    }
  }, [currentFrame]);

  React.useEffect(() => {
    const targetAnimation = vw > 685 ? desktopActions : mobileActions

    targetAnimation.forEach(item => {
      item.play()
      item.paused = true
    })
  }, [desktopActions, mobileActions])

  React.useEffect(() => {
    const scrollY = window.scrollY;
    const newFrame = getFrame([0, ...keyPositions], scrollY, pageHeight, vh, elemH);
    setCurrentFrame(newFrame);
  },[scroll])

  console.log(vw > 685)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="MAIN+MOB_3glb">
          <group name="BUBBLE_MOB+MAIN">
            <PerspectiveCamera name="CAMERA_MOB" makeDefault={vw < 685} far={100} near={0.1} fov={45}
                               position={[16.604, 0, 0]} rotation={[0, Math.PI / 2, 0]}/>
            <PerspectiveCamera name="Camera003" makeDefault={vw > 685} far={100} near={0.1} fov={22.895}
                               position={[16.604, 0, 0]} rotation={[0, Math.PI / 2, 0]}/>
            <mesh name="Cube006" geometry={nodes.Cube006.geometry} material={materials['Material.004']}
                  position={[-0.223, -0.027, -2.236]} rotation={[0.019, -0.63, 0.058]} scale={0.886}>
              <mesh name="Cube014" geometry={nodes.Cube014.geometry} material={materials['Material.005']}
                    position={[0.116, -0.02, 0.347]}/>
              <mesh name="Cube015" geometry={nodes.Cube015.geometry} material={materials['Material.005']}
                    position={[0.116, 0.36, 0.347]}/>
              <mesh name="Cube016" geometry={nodes.Cube016.geometry} material={materials['Material.005']}
                    position={[0.116, -0.381, 0.347]}/>
            </mesh>
            <mesh name="Cube013" geometry={nodes.Cube013.geometry} material={materials['Material.005']}
                  position={[-0.473, 0.826, -3.007]} rotation={[-3.123, 0.63, 3.084]} scale={0.886}/>
            <mesh name="Sphere008" geometry={nodes.Sphere008.geometry} material={materials['Material.005']}
                  position={[-1.238, -0.591, -0.472]} rotation={[0.019, -0.63, 0.058]} scale={0.343}/>
            <mesh name="Sphere009" geometry={nodes.Sphere009.geometry} material={materials['Material.005']}
                  position={[0.638, -0.954, -3.313]} rotation={[0.019, -0.63, 0.058]} scale={0.255}/>
          </group>
          <pointLight name="Area004" intensity={500} decay={2} position={[-0.599, 0, 8.238]} scale={5.831}/>
          <pointLight name="Area005" intensity={500} decay={2} position={[4.738, -2.554, -6.808]}
                      rotation={[2.659, 0.19, -0.346]} scale={5.831}/>
          <pointLight name="Area006" intensity={500} decay={2} position={[-0.121, 6.822, 0]}
                      rotation={[-Math.PI / 2, 0, 0]} scale={5.831}/>
          <pointLight name="Point002" intensity={500} decay={2} position={[-2.357, -6.107, 1.306]}
                      rotation={[-Math.PI / 2, 0, 0]}/>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/models/scene.gltf')
