import React from 'react';
import {setElemScrollPosition} from "../../services/setElemScrollPosition";
import {stateContext} from "../../store/store";

const Footer= () => {
  const {setKeyPositions} = React.useContext(stateContext)
  const elemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setElemScrollPosition(elemRef, setKeyPositions)
  }, []);
  return (
    <footer ref={elemRef} className="footer montserrat">
      <section id={'contacts'} className='container footer__contacts'>
        <div className="footer__content">
          <h3 className='unbounded'>Зв'яжіться з нами</h3>
          <div className="footer__phone-and-email">
            LLC.MARKET.CORP88@GMAIL.COM<br/>
            +38 (098) 364-20-65
          </div>
          <div className={'footer__address'}>
            Україна, 03189 м. Київ,<br/>
            вул. Вільямса Академіка, буд. 6-Д. оф.43
          </div>
        </div>
        <div className="target-position target-position__footer"/>
      </section>
      <div className='copyright montserrat'>© Корпорація маркетингу</div>
    </footer>
  );
};

export default Footer;