import React, {SetStateAction} from "react";

export const setElemScrollPosition = (elemRef: React.RefObject<HTMLDivElement>, setFun: React.Dispatch<SetStateAction<number[]>>) => {
  if (elemRef.current) {
    const rect = elemRef.current.getBoundingClientRect();
    setFun(prevState => {
      if (!prevState.includes(rect.top + window.scrollY))
        return [...prevState, rect.top + window.scrollY]
      else
        return prevState
    });
  }
}