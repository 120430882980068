import React from "react";

const useViewPort = () => {
  const [vh, setVh] = React.useState(window.innerHeight)
  const [vw, setVw] = React.useState(window.innerWidth)
  const [halfScrollPage, setHalfScrollPage] = React.useState(0)
  const [pageHeight, setPageHeight] = React.useState(0)

  React.useEffect(() => {
    if (halfScrollPage > 0 && pageHeight > 0)
      return
    setHalfScrollPage(document.body.scrollHeight / 2)
    setPageHeight(document.body.scrollHeight)

  }, [document.body.scrollHeight, window.innerHeight])

  React.useEffect(() => {
    const handlePageResize = () => {
      if (vw > 680 || vw != window.innerWidth)
        window.location.reload()
      setVh(window.innerHeight)
      setVw(window.innerWidth)
    }
    window.addEventListener('resize', handlePageResize);

    return () => {
      window.removeEventListener('resize', handlePageResize);
    };
  }, [])


  return {vh, vw, halfScrollPage, pageHeight}
}

export default useViewPort