import React, {useState} from 'react';
import Logo from "../../elements/Logo";
import {ReactComponent as Swipe} from "../../assets/swipe.svg";
import {Canvas} from "@react-three/fiber";
import useViewPort from "../../hooks/useViewPort";
import {Model} from "../../models/Scene";

const links: {[key: string]: string} = {
  "vacancies": "Вакансії",
  "who-we-are": "Хто ми",
  "why-we-are": "Чому ми",
  "comments": "Про нас говорять",
  "contacts": "Контакти"
}

const Header = () => {
  const [scroll, setScroll] = React.useState(0)
  const {vh, vw, halfScrollPage, pageHeight} = useViewPort()
  const elementRef = React.useRef<HTMLDivElement>(null);
  const [toVhBottom, setToVhBottom] = React.useState(0)
  const [pageHeightDefault, setPageHeightDefault] = useState(0)

  React.useEffect(() => {
    if (elementRef.current)
      setToVhBottom(vh - elementRef.current.scrollHeight)
  }, [elementRef])

  React.useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      if (scrollY > halfScrollPage)
        setScroll(scrollY + Math.min(toVhBottom, scrollY - halfScrollPage))
      else
        setScroll(scrollY)

    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [halfScrollPage, window.scrollY, vh]);

  return (
    <header className={"header"}>
      <div className={"header__bg"}/>
      <section className={'nav-bar container'}>
        <Logo/>
        <nav>
          <ul className={'montserrat'}>
            {
              Object.keys(links).map(item =>
                <li key={item}><a href={`#${item}`}>{links[item]}</a></li>
              )
            }
            <li><a href="#send-cv">
              <div className='button button__primary button__link'>
                Надіслати резюме
              </div>
            </a></li>
          </ul>
        </nav>
      </section>

      <section className={"header__content container"}>
        <div
          ref={elementRef}
          style={{transform: `translateY(${Math.max(vw > 685 ? 70 : 0,scroll)}px)`}}
          className="scene-container">
          <Canvas className={"scene"}>
            <Model scroll={scroll} elemH={elementRef.current ? elementRef.current.scrollHeight : 0}/>
          </Canvas>
        </div>

        <div className={"header__text"}>
          <h1 className={'unbounded'}>Професійна<br/>клієнтська підтримка</h1>
          <p className={'montserrat'}>Ми – Корпорація Маркетингу що спеціалізується на вибудовуванні довгострокових
            взаємовідносин із клієнтами
            та рівнем їх задоволеності.</p>
        </div>
        <div className={"target-position target-position__header"}/>
      </section>
      <div className={'header__swipe'}>
        <a href="#vacancies"><div className={'swipe-rect'}><Swipe/></div></a>
        <a href="#send-cv">
          <div className='button button__primary button__link montserrat'>
            Надіслати резюме
          </div>
        </a>
      </div>
    </header>
  );
};

export default Header;