import React from 'react';
import Argument from "../elements/Argument";
import {default as argumentsData} from "../data/why_we_are.json"
import {stateContext} from "../store/store";
import {setElemScrollPosition} from "../services/setElemScrollPosition";

const WhyWeAre = () => {
  const {setKeyPositions} = React.useContext(stateContext)
  const elemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setElemScrollPosition(elemRef, setKeyPositions)
  }, []);

  return (
    <section id={"why-we-are"} className="container why-we-are">
      <h2 ref={elemRef}>Чому <span>ми</span></h2>
      <div className={'argument-list'}>
        {
          argumentsData.map((item, i) => <Argument key={i+1} count={i + 1} argument={item}/>)
        }
        <img className={"circle circle__orange"} src={require("../assets/circle-orange.png")} alt=""/>
        <img className={"circle circle__blue"} src={require("../assets/circle-blue.png")} alt=""/>
      </div>
    </section>
  );
};

export default WhyWeAre;