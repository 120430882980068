import React from 'react';
import Vacancies from "../Vacancies";
import WhoWeAre from "../WhoWeAre";
import WhyWeAre from "../WhyWeAre";
import SendCV from "../SendCV";
import Comments from "../Comments";

const Content = () => {
  return (
    <main>
      <Vacancies/>
      <WhoWeAre/>
      <WhyWeAre/>
      <SendCV/>
      <Comments/>
    </main>
  );
};

export default Content;