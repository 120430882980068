import React, {SetStateAction} from "react";

interface ContextType {
  keyPositions: number[],
  setKeyPositions: React.Dispatch<SetStateAction<number[]>>
}

const initialState: ContextType = {
  keyPositions: [],
  setKeyPositions: () => {}
}

export const stateContext = React.createContext<ContextType>(initialState);