import React from 'react';
import {VacancyType} from "../types/VacancyType";
import VacancyStatus from "./VacancyStatus";
import {it} from "node:test";

interface Props {
  item: VacancyType,

  onClick?(item: VacancyType | null): void,

  isFrameHover?: boolean
  isDetails?: boolean
}

const Vacancy: React.FC<Props> = ({item, onClick, isDetails, isFrameHover}) => {

  const handleClick = () => {
    if (onClick) {
      onClick(item)
    }
  }

  return (
    <div className={`vacancy ${(item.status && !isDetails) ? "vacancy__active" : ""} ${isFrameHover ? "vacancy__frame-hover" : ""}`}>

      {
        (isDetails && !!onClick) &&
        <div onClick={() => onClick(null)} className={'back montserrat'}>Назад</div>
      }
      <div onClick={handleClick} className={'vacancy__header'}>
        <div className="vacancy__title unbounded">{item.title}</div>
        <VacancyStatus isDetails={isDetails} status={item.status}/>
      </div>
      {
        (isDetails && !!onClick) &&
        <>
          <div className={"vacancy__description"}>
            <div className={"vacancy__main-heading"}><b>Вимоги</b></div>
            <div hidden={!item.description?.hardSkills}>
              <div className={"vacancy__heading"}>Hard-skills</div>
              <ul>
                {item.description?.hardSkills.split("\n").map(i => <li key={i}>{i}</li>)}
              </ul>
            </div>
            <div hidden={!item.description?.softSkills}>
              <div className={"vacancy__heading"}>Soft-skills</div>
              <ul>
                {item.description?.softSkills.split("\n").map(i => <li key={i}>{i}</li>)}
              </ul>
            </div>
            <div hidden={!item.description?.responsibilities}>
              <div className={"vacancy__heading"}>Обов'язки</div>
              <ul>
                {item.description?.responsibilities.split("\n").map(i => <li key={i}>{i}</li>)}
              </ul>
            </div>
            <div hidden={!item.description?.offer}>
              <div className={"vacancy__heading"}>Ми пропонуємо</div>
              <ul>
                {item.description?.offer.split("\n").map(i => <li key={i}>{i}</li>)}
              </ul>
            </div>
            <span><b>Швидше відправляй своє CV та будемо знайомитись!</b></span>
          </div>
          <div className={"vacancy__footer__mobile"}>
            <a href="#send-cv"><button className={"respond"}>Відгукнутися</button></a>
            <div onClick={() => onClick(null)} className={'back montserrat'}>Назад</div>
          </div>
        </>
      }
    </div>
  );
};

export default Vacancy;