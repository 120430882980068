import React from 'react';
import {VacancyType} from "../types/VacancyType";
import Vacancy from "../elements/Vacancy";
import {default as data} from "../data/vacancies.json"
import {stateContext} from "../store/store";
import {setElemScrollPosition} from "../services/setElemScrollPosition";


const Vacancies = () => {
  const vacancies_data = data as VacancyType[]
  const [vacancy, setVacancy] = React.useState<VacancyType | null>(null)
  const [isHovered, setIsHovered] = React.useState(false)
  const {setKeyPositions} = React.useContext(stateContext)

  const onHover = () => {
    setIsHovered(prevState => !prevState)
  }


  const elemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setElemScrollPosition(elemRef, setKeyPositions)
  }, []);
  
  return (
    <section id={"vacancies"} className='vacancies container'>
      <img className={'done-img'} src={require("../assets/done.png")} alt=""/>

      <div ref={elemRef} className="frame">
        <div className="vacancies__header">
          <div><h2><span>Вакансії</span></h2></div>
        </div>


        <div onMouseEnter={onHover} onMouseLeave={onHover} className={'vacancies__content'}>
          {
            !vacancy
              ? vacancies_data.map(item => <Vacancy isFrameHover={isHovered} onClick={item.status ? setVacancy : () => {}} key={item.id} item={item}/>)

              : <div className="details">
                <Vacancy onClick={setVacancy} isDetails={true} item={vacancy}/>
              </div>
          }
        </div>
      </div>
    </section>
  );
};

export default Vacancies;