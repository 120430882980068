import React from 'react';
import {default as data} from "../data/comments.json"
import {CommentType} from "../types/CommentType";
import {ReactComponent as NavArrow} from "../assets/nav-arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider, {Settings} from "react-slick";
import {setElemScrollPosition} from "../services/setElemScrollPosition";
import {stateContext} from "../store/store";

const Comments = () => {
  const comments = data as CommentType[]

  const sliderRef = React.useRef<Slider | null>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  }
  const previous = () => {
    sliderRef.current?.slickPrev();
  }

  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    swipe: false,
    arrows: false
  };


  const {setKeyPositions} = React.useContext(stateContext)
  const elemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setElemScrollPosition(elemRef, setKeyPositions)
  }, []);
  return (
    <section ref={elemRef} id={"comments"} className={"container montserrat comments"}>
      <h2>Про нас говорять</h2>
      <div className="slider-wrapper">
        <Slider ref={sliderRef} {...settings}>
          {
            comments.map(item =>
              <div key={item.id}>
                <div className={"comment"}>

                  <p>{item.content}</p>
                  <div className={"comment__footer"}>
                    <div className={"comment__author"}>
                      <img src={item.author.avatar_url} alt=""/>
                      <div className={"comment__author-data"}>
                        <div className={"comment__author-name"}>{item.author.name} - {item.author.company}</div>
                        <div className={"comment__author-position"}>{item.author.position}</div>
                      </div>
                    </div>

                    <div className={'comment__navigation'}/>
                  </div>


                </div>
              </div>
            )
          }
        </Slider>
        <div className={'slider__navigation'}>
          <button className={"comment__button"} onClick={previous}><NavArrow/></button>
          <button className={"comment__button"} onClick={next}><NavArrow/></button>
        </div>
      </div>


    </section>
  );
};

export default Comments;