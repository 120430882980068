import React from 'react';

const Argument: React.FC<{ count: number, argument: { title: string, description: string } }> = ({count, argument}) => {
  return (
    <div
      className={`argument unbounded`}>
      <span>{argument.title}</span>
      <p className={'montserrat'}>{argument.description}</p>

      <div className={"argument__count"}>{count}</div>
    </div>
  );
};

export default Argument;