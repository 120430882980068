import React from 'react';
import {ReactComponent as LinkArrow} from "../assets/linked-arrow.svg";
import {VacancyType} from "../types/VacancyType";

interface Props {
  status: boolean,
  isDetails?: boolean
}

const VacancyStatus: React.FC<Props> = ({status, isDetails}) => {
  return status
    ? <div className={'vacancy__status vacancy__status__active montserrat'}>
      <img className={'vacancy__fire'} src={require("../assets/fire-emoji.png")} alt="🔥"/>
      <span>Актуальна</span>
      {
        isDetails
          ? <a href="#send-cv"><button className={"respond"}>Відгукнутися</button></a>
          : <div className={'arrow-link'}><LinkArrow/></div>
      }

    </div>
    : <div className={'vacancy__status montserrat'}>Закрита</div>
};

export default VacancyStatus;