import React from 'react';
import {ReactComponent as ClipIcon} from "../assets/clip.svg";

const InputFile: React.FC<{setFile(item: File | null): void}> = ({setFile}) => {


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files?.[0] || null);
  };

  return (
    <div>
      <input
        type="file"
        id="cv"
        className="file-input"
        onChange={handleFileChange}
        accept=".doc,.docx,.txt,.pdf"
        name={'cv'}
      />
      <label htmlFor="cv" className="file-label">
        <ClipIcon/>
      </label>
    </div>
  );
};

export default InputFile;