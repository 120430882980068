import React from 'react';
import CVForm from "./CVForm";
import {setElemScrollPosition} from "../services/setElemScrollPosition";
import {stateContext} from "../store/store";

const tagList = [
  "#Індивідуальний розвиток",
  "#Драйвові тімбілдинги",
  "#солодощі",
  "#Безкоштовна англійська",
  "#напої",
  "#Щотижнеі тренінги",
  "#Фрукти",
  "#Відсутність дрескоду",
  "#Кар'єрне зростання"
]

const statusDefault: {errors: any, success: boolean} = {
  errors: null,
  success: false
}

const SendCv = () => {
  const [status, setStatus] = React.useState(statusDefault)

  React.useEffect(()=>{
    if (status.errors)
      console.log(status.errors)
  }, [status.errors])

  const handleStatusChange = (success: boolean, errors: any) => {
    setStatus({success, errors})
  }


  const {setKeyPositions} = React.useContext(stateContext)
  const elemRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    setElemScrollPosition(elemRef, setKeyPositions)
  }, []);

  return (
    <section id={'send-cv'} className={"container send-cv"}>
      <h2 ref={elemRef}>Надсилай резюме, ставай <span>частиною команди</span></h2>

      <img className={"cv-dude cv-dude__big"} src={require("../assets/cv-dude.png")} alt=""/>
      <img className={"cv-dude cv-dude__small"} src={require("../assets/cv-dude.png")} alt=""/>
      <div className={`form-container ${(!status.errors && !status.success) ? "" : "form-container__sent"}`}>
        {
          (!status.errors && !status.success)
            ? <>
              <CVForm onStatusChange={handleStatusChange}/>
              <div className="tags">
                <div className="tag-area">
                  <div className={'tags__title unbounded'}>Робота у Корпорації Маркетингу це
                  </div>
                  {tagList.map(item => <div key={item} className={"tag montserrat"}>{item}</div>)}
                </div>
              </div>
            </>
            : <div className={"form-container__after-send"}>
              {
                !status.errors ?
                <>
                  <h2>Ваше резюме <span>надіслано</span></h2>
                  <p className={'montserrat'}>з вами зв'яжеться наш HR
                    спеціаліст <br/><b> майте гарний день!</b></p>
                </>
                  : <h2>Виникла <span>помилка</span></h2>
              }
            </div>
        }
      </div>
    </section>
  );
};

export default SendCv;