import React from 'react';

interface Prop{
  achievement?: string
  img_path?: string
}

const AchievementElem: React.FC<Prop> = ({achievement, img_path}) => {
  let result = achievement?.split(/(\d+\+?)/).filter(Boolean);

  const class_name = (!achievement && !img_path)
    ? 'empty'
    : (!!achievement && !img_path)
      ? 'texted'
      : (!achievement && !!img_path)
        ? 'imged'
        : ''

  return (
    <div className={`achievement ${class_name} unbounded`}>
      {
        (result && !img_path) &&
        <>
          <div className={'achievement__number'}>{result[0]}</div>
          <div className={'achievement__string'}>{result[1]}</div>
        </>
      }
      {
        (!result && img_path) &&
        <img src={img_path} alt=""/>
      }

    </div>
  );
};

export default AchievementElem;